<template>
  <div class="indexbox">
    <el-form ref="form" :model="form" :rules="rules" class="formbox">
      <div class="imgbox">
        <img src="../../assets/img/logo.png" alt="" />
        <p>Login to Assembly Order Portal</p>
      </div>
      <el-form-item prop="email">
        <span class="form_tit">Email*</span>
        <el-input v-model="form.email" clearable></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <span class="form_tit">Password*</span>
        <el-input v-model="form.password" type="password" maxlength="18" clearable show-password></el-input>
      </el-form-item>
      <div class="password">
        <p @click="show = true">Forgot Password?</p>
      </div>
      <el-form-item>
        <el-button type="primary" @click.native.prevent="onSubmit" :loading="loading">Login</el-button>
      </el-form-item>
      <div class="registerbox">
        <p>
          No Account ?
          <router-link to="register">Create One</router-link>
        </p>
      </div>
    </el-form>

    <el-dialog title="Forgot your password" :visible.sync="show" width="30%">
      <el-form ref="sendform" :model="sendform" :rules="sendrules" class="formbox">
        <el-form-item prop="email">
          <span class="form_tit">Email*</span>
          <el-input v-model="sendform.email" clearable></el-input>
        </el-form-item>
        <el-form-item class="btnbox">
          <el-button type="primary" @click.native.prevent="sendPasswordEmail" :loading="loadinga">Send Reset Link
          </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { setToken } from "@/utils/auth";
export default {
  data() {
    return {
      form: {
        email: "",//409097512@qq.com Krystal_mu@inno-is.com
        password: "",//a12345678  Angle2023krystal
      },
      loading: false,
      rules: {
        email: [
          {
            required: true,
            trigger: "blur",
            message: 'Please Enter Email',
          },
        ],
        password: [
          {
            required: true,
            trigger: "blur",
            message: 'Please Enter Password',
          },
        ],
      },
      show: false,
      sendform: {
        email: "",
      },
      sendrules: {
        email: [
          {
            required: true,
            trigger: "blur",
            message: 'Please Enter Email',
          },
        ],
      },
      loadinga: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    onSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$post({
            url: "/app/auth/login",
            data: this.form,
            success: (res) => {
              this.$message({
                message: res.msg,
                type: "success",
              });
              setToken(res.data.token);
              sessionStorage.setItem("activeIndex", "/");
              this.$store.dispatch("remove_API_ID");
              setTimeout(() => {
                this.$router.replace("/");
                this.loading = false;
              }, 1500);
            },
            tip: () => {
              setTimeout(() => {
                this.loading = false;
              }, 1500);
            },
          });
        }
      });
    },
    sendPasswordEmail() {
      this.$refs.sendform.validate((valid) => {
        if (valid) {
          this.loadinga = true;
          this.$post({
            url: "/app/auth/sendPasswordEmail",
            data: this.sendform,
            success: () => {
              this.$message({
                message: "Sent Successfully",
                type: "success",
              });
              setTimeout(() => {
                this.show = false;
                this.loadinga = false;
              }, 1500);
            },
            tip: () => {
              setTimeout(() => {
                this.loadinga = false;
              }, 1500);
            },
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.indexbox {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 80px;
  .formbox {
    width: 480px;
    padding: 10px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .imgbox {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 310px;
        height: 160px;
      }
      p {
        width: 100%;
        text-align: center;
        color: #3b3b3b;
        font-size: 26px;
        font-weight: 600;
        font-family: PingFang SC;
        padding: 40px 0;
      }
    }
    .password {
      width: 100%;
      p {
        color: $color;
        font-size: 16px;
        cursor: pointer;
      }
      margin-bottom: 40px;
    }
    /deep/.el-form-item {
      width: 100%;
      margin-bottom: 30px !important;
      .el-input {
        .el-input__inner {
          height: 50px;
        }
      }
      .el-form-item__content {
        width: 100%;
        .el-button {
          width: 100%;
          height: 48px;
          background: $color !important;
          border-radius: 5px;
          border-color: $color;
        }
      }
    }
    .registerbox {
      width: 100%;
      text-align: center;
      color: #3b3b3b;
      font-size: 16px;
      padding-top: 10px;
      a {
        color: $color;
        font-size: 16px;
      }
    }
  }
}
</style>
